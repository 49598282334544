import React from 'react'
import { Link } from 'gatsby'
// import { render } from 'react-dom'
import Gallery from 'react-grid-gallery'
import Layout from '../components/layout'
import {Col, Row} from 'react-bootstrap'
import './pages.css'

const IMAGES =
[{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/1_8525161297.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/1_320x50_516c01cba8.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/2_908d7178ec.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/2_320x212_106daa57e7.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/3_58e741288c.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/3_320x212_3ee3d6de3d.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/4_6c258ef22a.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/4_320x212_2738220b14.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/5_3c24b4c589.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/5_320x212_5f96efeb40.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/6_e0ceacb90e.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/6_320x212_00f568b42c.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/7_1524f34412.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/7_320x212_370082da6b.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/8_00a13ea185.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/8_320x212_1d2498340d.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/9_9f6afeac8f.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/9_320x212_af330a4312.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/10_662dd7ce2a.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/10_320x212_e7509f4069.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/11_e8fec21894.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/11_320x212_a6a3b2965d.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/12_4808fa2440.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/12_320x212_98073dd152.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/13_fa84079175.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/13_320x212_f7ebc29ff5.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/14_d15ac70958.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/14_320x212_13487a3ade.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/15_c2a9441061.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/15_320x212_a9a895f1eb.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/16_322fb348a5.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/16_320x212_fd56d41edb.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/17_15fb727e74.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/17_320x212_9a790cb3ce.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/18_b02994ee38.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/18_320x212_482a1dfe05.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/19_754019b42b.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/19_320x212_8f0215afe2.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/20_170149012f.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/20_320x212_753380bc25.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/21_9f91d1ff9d.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/21_320x212_6eb31d3639.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/22_9af55d2347.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/22_320x212_8f4e6bb912.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/23_2029ccb76b.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/23_320x212_1e8248208b.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/24_4c96f437e3.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/24_320x212_8cada79fee.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/25_ec20ff285d.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/25_320x212_0ca59be1b4.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/26_9da46a0ad4.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/26_320x212_9b9bc5c51e.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/27_10818dd89e.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/27_320x212_83f4c5401f.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/28_516a95d97e.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/28_320x212_9d83ba55e8.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/29_ff2670ba41.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/29_320x212_392ff628a6.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/30_1ceef46aea.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/30_320x212_b4ae9d51fa.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
},
{
        src: "https://anzac-production.s3.us-west-2.amazonaws.com/31_9d5c8d7526.jpg",
        thumbnail: "https://anzac-production.s3.us-west-2.amazonaws.com/31_320x212_9e916818b1.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
}]


export default () => (
  
  <Layout pageTitle='Photo Gallery' layoutClass='main-page'>
    <ul className='d-flex list-unstyled breadcrumbs mb-5'>
      <li className='bread-link'><Link to='/'>Home</Link></li>
      <li className='bread-link'><Link to='/photo-gallery'>Photo Gallery</Link></li>
    </ul>
    <Row>
    <Col xs={12}>
        <h2>Centenary of the Formation of Anzac Day Commemoration Committee</h2>
        <div style={{
        display: "block",
        minHeight: "1px",
        width: "100%",
        overflow: "auto"}}>
    <Gallery images={IMAGES} enableImageSelection={false} backdropClosesModal={true}/>
    </div>
    </Col>
    </Row>

  </Layout>
)